/* eslint-disable no-useless-escape */
/**
 * @todo
 * Waiting for next stage to implement this feature.
 */

import { useProducts, UseProductsResponse } from "@with-nx/ecommerce";
import { Formatter, useCMSClients, useMobile } from "@with-nx/hooks-n-helpers";
import {
  DesignedCard,
  DesignedPagination,
  LoadingShimmer,
  Rule,
} from "@with-nx/simple-ui/atoms";
import { ReusableSection } from "@with-nx/simple-ui/atoms";
import {
  CatalogLayout,
  GridBlog,
  GridStepScroller,
  ReusableDoubleGradientCard,
  ReusableGradientHero,
  ReusablePreSlantedContainer,
  ReusableSlantedContainer,
} from "@with-nx/simple-ui/organisms";
import { Col, Row } from "antd";
import { useState } from "react";
import { Box } from "simple-effing-primitive-layout";
import Image from "next/image";
import ReusableGridProduct from "../../components/w/ReusableGridProduct";

const ScrollerItems = [
  {
    title: "CONVERT TO COMMERCIAL PRODUCT",
    description:
      "We work directly with you to adapt original materials to fit all licensed script versions, and then integrate those materials into class-leading I.P. protection software that allows use in both practical and educational environments.",
    gradients: ["#9231B4", "#9231B4"],
  },
  {
    title: "PROTECT AND PROMOTE",
    description:
      "We provide individualized promotional strategies to elevate your work through high-profile organizations to advertise availability. If necessary, we also provide assistance with Federal Copyright protections.",
    gradients: ["#B43131", "#B43131"],
  },
  {
    title: "LICENSE AND MONITOR REPRODUCTION RIGHTS",
    description:
      "We protect, license and market through our niche distribution framework to an untapped market of theatre-makers; and collect royalties on your behalf.",
    gradients: ["#1C49E9", "#1C49E9"],
  },
];

const DirectFromBroadway = ({
  loading,
  selectedShowBrandIds,
  selectedShowIds,
}: {
  loading: boolean;
  selectedShowBrandIds: string[];
  selectedShowIds: string[];
}) => {
  const { products, isLoading } = useProducts({
    currentPage: 1,
    pageSize: 24,
    selectedShowIds: selectedShowIds,
    selectedShowBrandIds: selectedShowBrandIds,
    productCategory: "global",
    throttler: true,
  });
  const allProducts: UseProductsResponse = products;

  if (isLoading || loading) {
    return null;
  }

  return (
    <ReusablePreSlantedContainer>
      <div className="container mx-auto max-w-7xl">
        <ReusableSection>
          <div className="container">
            <Rule parse="!hl d:block mb:24">Products Direct from Broadway</Rule>
          </div>
          <ReusableGridProduct
            loading={isLoading}
            productCategory="global"
            products={allProducts}
          />
        </ReusableSection>
      </div>
    </ReusablePreSlantedContainer>
  );
};

export default function Page({ resolvedUrl }) {
  const [page, _page] = useState(0);

  const clients = useCMSClients({
    page,
  });

  const mobile = useMobile();

  const selectedShowIds: string[] = [];
  const selectedShowBrandIds: string[] = [];

  clients.clients?.forEach((client) => {
    if (client.showBrandTags?.length > 0) {
      client.showBrandTags.forEach((tag) => {
        selectedShowBrandIds.push(tag);
      });
    }
    if (client.showVariantTags?.length > 0) {
      client.showVariantTags.forEach((tag) => {
        selectedShowIds.push(tag);
      });
    }
  });

  return (
    <CatalogLayout
      footerNoAngle
      transparentFixedNavBar
      title="Broadway Media - Clients"
      resolvedUrl={resolvedUrl}
    >
      {clients.loading ? (
        <LoadingShimmer height={500} />
      ) : (
        <ReusableGradientHero
          slanted={!mobile}
          backdrop="/assets/hero/contact-us-gradient-webp.webp"
          src="/assets/hero/clients-hero-2.webp"
          right={
            <Box
              parse={`w:100% d:flex ${
                !mobile ? "h:100% j:flex-end pt:58px" : "j:center"
              }`}
            >
              <Image
                src="/assets/logo/direct-from-broadway.svg"
                width={mobile ? 300 : 400}
                height={mobile ? 80 : 160}
              />
            </Box>
          }
        >
          <>
            <Rule parse="!_t d:block mb:12">Clients</Rule>
            <Rule parse="!_p d:block mb:32">
              Broadway Media is privileged to represent the creative works of so
              many amazing theatre professionals. We are honored to make their
              inspirational work accessible to theatre-makers around the world.
            </Rule>
          </>
        </ReusableGradientHero>
      )}
      <div className="container mx-auto max-w-7xl">
        <ReusableSection>
          <div className="container">
            <Rule parse="!hl d:block mb:24">Clients</Rule>
          </div>
          <Row gutter={[36, 18]}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Row gutter={[36, 18]}>
                {clients.loading
                  ? [...Array(12)].map((item, i) => (
                      <Col xs={24} sm={12} md={12} lg={8} xl={6} key={i}>
                        <GridBlog
                          properties={{ height: "100%" }}
                          key={i}
                          loading={true}
                        />
                      </Col>
                    ))
                  : clients.clients.map((item, i) => (
                      <Col xs={24} sm={12} md={12} lg={8} xl={6} key={i}>
                        <GridBlog
                          properties={{ height: "100%" }}
                          label={`More by ${item.title}`}
                          url={`/clients/${item.slug}`}
                          title={item.title}
                          description={item.description}
                          image={Formatter.compressed(item.image, 256, true)}
                        />
                      </Col>
                    ))}
              </Row>
              <Box css="d:flex a:center j:center" parse="mt:24">
                {clients.clients.length > 0 &&
                Number(clients?.pages + 1) > 1 ? (
                  <div className="flex items-center justify-end">
                    <DesignedPagination
                      mode="lines"
                      size={clients.pages + 1}
                      value={clients.page}
                      label={(index) => index + 1}
                      press={(index) => {
                        _page(index);
                      }}
                    />
                  </div>
                ) : undefined}
              </Box>
            </Col>
          </Row>
        </ReusableSection>
      </div>
      <DirectFromBroadway
        loading={clients.loading}
        selectedShowBrandIds={selectedShowBrandIds}
        selectedShowIds={selectedShowIds}
        key="direct-from-broadway"
      />
      <ReusableSlantedContainer
        direction="115deg"
        gradient={["#301EFF", "#420689", "#8923E9"]}
        feather={["transparent", "var(--background)", "var(--background)"]}
        bottom={100}
      >
        <div className="container">
          <Row gutter={[38, 18]} align="bottom" justify="space-between">
            <Col xs={24} sm={24} md={8} lg={8}>
              <Box css="d:inline-block p:relative oa:visible">
                <Rule parse="!hl mb:12 d:block">
                  What Exists beyond Broadway?
                </Rule>
              </Box>
              <Rule parse="!pl mb:32 d:block">
                Critical intellectual property protection. New revenue
                opportunities. Streamlined licensing services that expand access
                to original production design.
              </Rule>
            </Col>
          </Row>
          <Row gutter={[38, 18]} align="top" justify="space-between">
            <Col xs={24} sm={24} md={12}>
              <Rule parse="!pm d:block mb:12 c:?font2">
                Your original designs are vital to the realization and success
                of a Broadway show. We think it&quot;s time you received the
                same post-Broadway property protection and revenue generation
                that authors do. This gives you the freedom to push design
                boundaries, enjoy greater financial security, and gain exposure
                to a huge audience of theatre-makers.
              </Rule>
              <Rule parse="!pm d:block mb:36 c:?font2">
                Theatrical resources that distill complex media into intuitive
                &quot;plug and play&quot; technologies have become a force in
                schools and colleges, as well as community and professional
                regional theaters. Currently, this market experiences massive
                fragmentation. Because of this, the 1.4B secondary performance
                resource market is ripe for this kind of innovation
              </Rule>
              <Rule parse="!pm d:block mb:36 c:?font2">
                DesignWorks provides a marketplace solution that reduces
                friction in the hyper-targeted secondary market. We have created
                a model to represent original design work, create derivative
                commercial products and deliver via software through an
                established licensing system.
              </Rule>
              <Rule parse="!pm d:block mb:36 c:?font2">
                Through strategic distribution partnerships with the
                world&quot;s leading grand rights theatrical licensing agencies,
                we&quot;ve recently expanded our services into the Australasia
                and Europe licensing regions. This gives us unprecedented reach
                into the global secondary theatrical market.
              </Rule>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <DesignedCard
                image="/assets/section/clients-section-image.webp"
                size="16x9"
              />
            </Col>
          </Row>
        </div>
      </ReusableSlantedContainer>
      <ReusableSection>
        <div className="container">
          <Rule parse="!dt mb:24 d:block ta:center">
            We Help To Empower the Theatre Community
          </Rule>
          <GridStepScroller
            xs={24}
            sm={24}
            md={8}
            items={ScrollerItems.map((item) => (
              <ReusableDoubleGradientCard
                properties={{ height: 256 }}
                bottomless={true}
                key={2}
                gradients={item.gradients as [string, string]}
                holder={false}
                bright={true}
              >
                <Box css="h:100%">
                  <Rule parse="!ll d:block mb:12 c:?font1 w:90%" weight="700">
                    {item.title}
                  </Rule>
                  <Rule parse="!lm d:block mb:24 c:?font2">
                    {item.description}
                  </Rule>
                </Box>
              </ReusableDoubleGradientCard>
            ))}
          />
        </div>
      </ReusableSection>
      <ReusableSection>
        <div className="container">
          <Row gutter={[38, 18]}>
            <Col xs={24}>
              <Rule parse="!hl d:block" css="--blue-gradient-text">
                Interested in representation?
              </Rule>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8}>
              <Rule parse="!hl d:block mb:12 c:?font2">
                DesignWorks Licensing
              </Rule>
              <Rule parse="!pm d:block mb:6 c:?font3">(646) 435-1522</Rule>
              <Rule parse="!pm d:block mb:6 c:?font3">
                447 Broadway, 2nd Floor
              </Rule>
              <Rule parse="!pm d:block mb:6 c:?font3">New York, NY 10013</Rule>
            </Col>
            <Col xs={24} sm={24} md={12} lg={16}>
              <Row gutter={[38, 18]}>
                <Col xs={24}>
                  <Rule parse="!hs d:block">Our Team</Rule>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Box parse="pa:20 br:10 c:?foreground" css="d:flex a:center">
                    <img
                      loading="lazy"
                      height="80"
                      width="80"
                      src="/assets/team/quentin.webp"
                      className="--smoother"
                      style={{
                        borderRadius: 10,
                      }}
                      alt="Quentin Sanford"
                    />

                    <Box parse="ml:20 f:1">
                      <Rule parse="!ll d:block">Quentin Sanford</Rule>
                      <Rule parse="!ls d:block" weight={400}>
                        President
                      </Rule>
                    </Box>
                  </Box>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12}>
                  <Box parse="pa:20 br:10 c:?foreground" css="d:flex a:center">
                    <img
                      loading="lazy"
                      height="80"
                      width="80"
                      src={"/assets/team/paul.webp"}
                      className="--smoother"
                      style={{
                        borderRadius: 10,
                      }}
                      alt="Paul Canaan"
                    />

                    <Box parse="ml:20 f:1">
                      <Rule parse="!ll d:block">Paul Canaan</Rule>
                      <Rule parse="!ls d:block" weight={400}>
                        Creative Director
                      </Rule>
                    </Box>
                  </Box>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </ReusableSection>
    </CatalogLayout>
  );
}

export const getServerSideProps = async ({ resolvedUrl }) => {
  return {
    props: {
      resolvedUrl,
    },
  };
};
